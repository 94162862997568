import React, { memo } from 'react';

import * as Component from './styled';

interface Props {
    label?: string;
    onClick?: () => void;
}

const ButtonFilter: React.FC<Props> = ({ label, onClick }) => {
    return (
        <Component.ButtonFilter color="primary" onClick={onClick}>
            <Component.IconFilter />
            {label}
        </Component.ButtonFilter>
    );
};

export default memo(ButtonFilter);
