import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PERMISSION_ADMIN, PERMISSION_ADMIN_CUSTOMER } from '../helpers';

import * as Component from './styled';

interface PropsModule {
    name: string;
    label: string;
    expanded: boolean;
    active: string | null;
    onChange: any;
    levelAccess: number | undefined;
    goToPage(link: string): void;
}

const ItemMenuModule: React.FC<PropsModule> = ({ name, label, expanded, active, onChange, levelAccess, goToPage }) => {
    const { t: translate } = useTranslation();

    if (name === 'compliance') {
        return (
            <Component.Accordion expanded={expanded} onChange={onChange}>
                <Component.AccordionSummary>
                    <Component.ListItem button>
                        <Component.ReportingChannel />
                        <Component.ListItemText primary={label} />
                        <Component.ExpandMoreIcon />
                    </Component.ListItem>
                </Component.AccordionSummary>
                <Component.AccordionDetails>
                    <Component.List>
                        <Component.ListItem
                            button
                            onClick={() => goToPage('/complaints')}
                            active={Boolean(active === 'complaints').toString()}
                        >
                            <Component.ListItemText primary={translate('navbar.communication')} />
                        </Component.ListItem>
                        <Component.Visible condition={PERMISSION_ADMIN.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/complaints/settings')}
                                active={Boolean(active === 'settings').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.settings')} />
                            </Component.ListItem>
                        </Component.Visible>
                    </Component.List>
                </Component.AccordionDetails>
            </Component.Accordion>
        );
    }
    if (name === 'due-diligence') {
        return (
            <Component.Accordion expanded={expanded} onChange={onChange}>
                <Component.AccordionSummary>
                    <Component.ListItem button>
                        <Component.DueDiligence />
                        <Component.ListItemText primary={label} />
                        <Component.ExpandMoreIcon />
                    </Component.ListItem>
                </Component.AccordionSummary>
                <Component.AccordionDetails>
                    <Component.List>
                        <Component.ListItem
                            button
                            onClick={() => goToPage('/due-diligence')}
                            active={Boolean(active === 'due-diligence').toString()}
                        >
                            <Component.ListItemText primary={translate('navbar.suppliers')} />
                        </Component.ListItem>
                        <Component.Visible condition={PERMISSION_ADMIN_CUSTOMER.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/due-diligence/categories')}
                                active={Boolean(active === 'due-diligence_categories').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.categories')} />
                            </Component.ListItem>
                        </Component.Visible>

                        <Component.Visible condition={PERMISSION_ADMIN.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/due-diligence/questions')}
                                active={Boolean(active === 'due-diligence_questions').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.questions')} />
                            </Component.ListItem>
                        </Component.Visible>
                    </Component.List>
                </Component.AccordionDetails>
            </Component.Accordion>
        );
    }
    if (name === 'lgpd') {
        return (
            <Component.Accordion expanded={expanded} onChange={onChange}>
                <Component.AccordionSummary>
                    <Component.ListItem button>
                        <Component.Lgpd />
                        <Component.ListItemText primary={label} />
                        <Component.ExpandMoreIcon />
                    </Component.ListItem>
                </Component.AccordionSummary>
                <Component.AccordionDetails>
                    <Component.List>
                        <Component.ListItem
                            button
                            onClick={() => goToPage('/lgpd')}
                            active={Boolean(active === 'lgpd').toString()}
                        >
                            <Component.ListItemText primary={translate('navbar.suppliers')} />
                        </Component.ListItem>
                        <Component.Visible condition={PERMISSION_ADMIN_CUSTOMER.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/lgpd/categories')}
                                active={Boolean(active === 'lgpd_categories').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.categories')} />
                            </Component.ListItem>
                        </Component.Visible>
                        <Component.Visible condition={PERMISSION_ADMIN.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/lgpd/question-group')}
                                active={Boolean(active === 'lgpd_question_group').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.questionGroup')} />
                            </Component.ListItem>
                        </Component.Visible>
                        <Component.Visible condition={PERMISSION_ADMIN.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/lgpd/questions')}
                                active={Boolean(active === 'lgpd_questions').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.questions')} />
                            </Component.ListItem>
                        </Component.Visible>
                    </Component.List>
                </Component.AccordionDetails>
            </Component.Accordion>
        );
    }
    if (name === 'ead') {
        return (
            <Component.Accordion expanded={expanded} onChange={onChange}>
                <Component.AccordionSummary>
                    <Component.ListItem button>
                        <Component.Ead />
                        <Component.ListItemText primary={label} />
                        <Component.ExpandMoreIcon />
                    </Component.ListItem>
                </Component.AccordionSummary>
                <Component.AccordionDetails>
                    <Component.List>
                        <Component.ListItem
                            button
                            onClick={() => goToPage('/ead')}
                            active={Boolean(active === 'ead').toString()}
                        >
                            <Component.ListItemText primary={translate('navbar.courses')} />
                        </Component.ListItem>
                        <Component.Visible condition={PERMISSION_ADMIN.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/ead/categories')}
                                active={Boolean(active === 'ead_categories').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.categories')} />
                            </Component.ListItem>
                        </Component.Visible>
                        <Component.Visible condition={PERMISSION_ADMIN.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/ead/job-candidates')}
                                active={Boolean(active === 'job-candidates').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.candidates')} />
                            </Component.ListItem>
                        </Component.Visible>
                        <Component.ListItem
                            button
                            onClick={() => goToPage('/ead/my-courses')}
                            active={Boolean(active === 'my-courses').toString()}
                        >
                            <Component.ListItemText primary={translate('navbar.myCourses')} />
                        </Component.ListItem>
                        <Component.Visible condition={PERMISSION_ADMIN.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/ead/notifications')}
                                active={Boolean(active === 'notificationsEad').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.notifications')} />
                            </Component.ListItem>
                        </Component.Visible>
                        <Component.Visible condition={PERMISSION_ADMIN.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/ead/dashboard')}
                                active={Boolean(active === 'dashboard').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.overview')} />
                            </Component.ListItem>
                        </Component.Visible>
                        {/* <Component.Visible condition={PERMISSION_ADMIN.includes(levelAccess as number)}>
                            <Component.ListItem
                                button
                                onClick={() => goToPage('/ead/users')}
                                active={Boolean(active === 'users').toString()}
                            >
                                <Component.ListItemText primary={translate('navbar.users')} />
                            </Component.ListItem>
                        </Component.Visible> */}
                    </Component.List>
                </Component.AccordionDetails>
            </Component.Accordion>
        );
    }
    if (name === 'syndication') {
        return (
            <Component.Accordion expanded={expanded} onChange={onChange}>
                <Component.AccordionSummary>
                    <Component.ListItem button>
                        <Component.Syndication />
                        <Component.ListItemText primary={label} />
                        <Component.ExpandMoreIcon />
                    </Component.ListItem>
                </Component.AccordionSummary>
                <Component.AccordionDetails>
                    <Component.List>
                        <Component.ListItem
                            button
                            onClick={() => goToPage('/syndication')}
                            active={Boolean(active === 'schedules').toString()}
                        >
                            <Component.ListItemText primary={translate('navbar.schedules')} />
                        </Component.ListItem>
                    </Component.List>
                </Component.AccordionDetails>
            </Component.Accordion>
        );
    }
    return null;
};

export default memo(ItemMenuModule);
