import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import { setPrefix } from 'utils';

import Loading from 'components/loading';

const Layout = lazy(() => import('components/layout'));

const Home = lazy(() => import('pages/home'));

const RegistrationSupplier = lazy(() => import('pages/registrationSupplier'));

// Rotas do módulo de Complaint
const Complaints = lazy(() => import('pages/modules/complaints/home'));
const Details = lazy(() => import('pages/modules/complaints/details'));
const Settings = lazy(() => import('pages/modules/complaints/settings'));

// Rotas do módulo de Due Diligencia
const DueDiligence = lazy(() => import('pages/modules/dueDiligence/home'));
const Questionnaires = lazy(() => import('pages/modules/dueDiligence/questionnaires'));
const Quiz = lazy(() => import('pages/modules/dueDiligence/quiz'));
const Questions = lazy(() => import('pages/modules/dueDiligence/questions'));

// Rotas do módulo de LGPD
const Lgpd = lazy(() => import('pages/modules/lgpd/home'));
const QuestionnairesLgpd = lazy(() => import('pages/modules/lgpd/questionnaires'));
const QuizLgpd = lazy(() => import('pages/modules/lgpd/quiz'));
const QuestionsLgpd = lazy(() => import('pages/modules/lgpd/questions'));
const QuestionGroupLgpd = lazy(() => import('pages/modules/lgpd/group'));

// Rotas do módulo de EAD
const Ead = lazy(() => import('pages/modules/ead/home'));
const Course = lazy(() => import('pages/modules/ead/course'));
const Classes = lazy(() => import('pages/modules/ead/classroom'));
const MyCourses = lazy(() => import('pages/modules/ead/myCourses'));
const Dashboard = lazy(() => import('pages/modules/ead/dashboard'));
const AssociateUsers = lazy(() => import('pages/modules/ead/users'));
const JobCandidates = lazy(() => import('pages/modules/ead/jobCandidates'));
const NotificationsEad = lazy(() => import('pages/modules/ead/notifications'));

// Rotas do módulo de Syndication
const Syndication = lazy(() => import('pages/modules/syndication'));

// Rotas das páginas compartilhadas do sistema
const Profile = lazy(() => import('pages/profile'));
const Clients = lazy(() => import('pages/clients'));
const Users = lazy(() => import('pages/users'));
const Categories = lazy(() => import('pages/categories'));
const Page404 = lazy(() => import('pages/404'));

// Rotas publicas
const ExternalQuiz = lazy(() => import('pages/modules/lgpd/externalQuiz'));

const Admin: React.FC = () => (
    <Suspense fallback={<Loading />}>
        <Router>
            <Layout>
                <Routes>
                    <Route path={setPrefix('/')} element={<Home />} />
                    <Route
                        path={setPrefix('/registration-supplier/:uuid/:user_id')}
                        element={<RegistrationSupplier />}
                    />
                    <Route path={setPrefix('/complaints')} element={<Complaints />} />
                    <Route path={setPrefix('/complaints/classification/:classification')} element={<Complaints />} />
                    <Route path={setPrefix('/complaints/company/:company_id')} element={<Complaints />} />
                    <Route path={setPrefix('/complaints/status/:status_id')} element={<Complaints />} />
                    <Route
                        path={setPrefix('/complaints/classification/:classification/company/:company_id')}
                        element={<Complaints />}
                    />
                    <Route
                        path={setPrefix('/complaints/company/:company_id/status/:status_id')}
                        element={<Complaints />}
                    />
                    <Route
                        path={setPrefix('/complaints/classification/:classification/status/:status_id')}
                        element={<Complaints />}
                    />
                    <Route
                        path={setPrefix(
                            '/complaints/classification/:classification/company/:company_id/status/:status_id',
                        )}
                        element={<Complaints />}
                    />
                    <Route path={setPrefix('/complaints/details/:id')} element={<Details />} />
                    <Route path={setPrefix('/complaints/settings')} element={<Settings />} />
                    <Route path={setPrefix('/due-diligence')} element={<DueDiligence />} />
                    <Route path={setPrefix('/due-diligence/questions')} element={<Questions />} />
                    <Route
                        path={setPrefix('/due-diligence/questionnaires/:supplier_id')}
                        element={<Questionnaires />}
                    />
                    <Route
                        path={setPrefix('/due-diligence/questionnaires/:supplier_id/quiz/:quiz_id')}
                        element={<Quiz />}
                    />
                    <Route path={setPrefix('/lgpd')} element={<Lgpd />} />
                    <Route path={setPrefix('/lgpd/questions')} element={<QuestionsLgpd />} />
                    <Route path={setPrefix('/lgpd/question-group')} element={<QuestionGroupLgpd />} />
                    <Route path={setPrefix('/lgpd/questionnaires/:supplier_id')} element={<QuestionnairesLgpd />} />
                    <Route path={setPrefix('/lgpd/questionnaires/:supplier_id/quiz/:quiz_id')} element={<QuizLgpd />} />
                    <Route path={setPrefix('/ead')} element={<Ead />} />
                    <Route path={setPrefix('/ead/my-courses')} element={<MyCourses />} />
                    <Route path={setPrefix('/ead/dashboard')} element={<Dashboard />} />
                    <Route path={setPrefix('/ead/users')} element={<AssociateUsers />} />
                    <Route path={setPrefix('/ead/job-candidates')} element={<JobCandidates />} />
                    <Route path={setPrefix('/ead/notifications')} element={<NotificationsEad />} />
                    <Route path={setPrefix('/ead/dashboard/student/:user_id')} element={<MyCourses />} />
                    <Route path={setPrefix('/ead/course/add')} element={<Course />} />
                    <Route path={setPrefix('/ead/course/edit/:course_id')} element={<Course />} />
                    <Route path={setPrefix('/ead/course/view/:course_id')} element={<Classes />} />
                    <Route
                        path={setPrefix('/ead/course/view/:course_id/:module_id/:lesson_id')}
                        element={<Classes />}
                    />
                    <Route path={setPrefix('/ead/category/:category_id')} element={<Ead />} />
                    <Route path={setPrefix('/syndication')} element={<Syndication />} />
                    <Route path={setPrefix('/syndication/details/:syndication_id')} element={<Syndication />} />
                    <Route path={setPrefix('/profile')} element={<Profile />} />
                    <Route path={setPrefix('/users')} element={<Users />} />
                    <Route path={setPrefix('/:module_name/categories')} element={<Categories />} />
                    <Route path={setPrefix('/clients')} element={<Clients />} />
                    <Route path={setPrefix('/registration-lgpd-quiz/:user_id/:uuid')} element={<ExternalQuiz />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Layout>
        </Router>
    </Suspense>
);

export default Admin;
