import React, { memo } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { v4 as uuidv4 } from 'uuid';

import { PropsSelect } from 'models';

import * as Component from './styled';

interface Props {
    color?: 'primary' | 'secondary';
    freeSolo?: boolean;
    size?: 'small' | 'medium';
    label?: string;
    name?: string;
    value?: string;
    noOptionsText?: string;
    options: PropsSelect[];
    onChange?: any;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    onChangeText?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const InputSearch: React.FC<Props> = ({
    color,
    freeSolo,
    size,
    options,
    label,
    name,
    value,
    error,
    helperText,
    noOptionsText,
    onChange,
    disabled,
    onChangeText,
    onKeyDown,
}) => (
    <Component.ThemeProvider theme={Component.themeSelectDark}>
        <Component.InputSearchCustom
            autoComplete
            includeInputInList
            freeSolo={freeSolo}
            size={size ?? 'small'}
            inputValue={value}
            options={options}
            getOptionLabel={(option: any) => option.title}
            noOptionsText={noOptionsText}
            forcePopupIcon={false}
            onChange={onChange}
            onKeyDown={onKeyDown}
            disabled={disabled ?? false}
            renderInput={(params: any) => (
                <Component.InputCustom
                    color={color}
                    fullWidth
                    {...params}
                    placeholder={label}
                    variant="outlined"
                    name={name}
                    value={value}
                    onChange={onChangeText}
                    error={error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Component.InputAdornment position="end">
                                <Component.SearchIcon />
                            </Component.InputAdornment>
                        ),
                    }}
                />
            )}
            renderOption={(option: any, { inputValue }) => {
                const matches = match(option.title, inputValue);
                const parts = parse(option.title, matches);
                return (
                    <div>
                        {parts.map((part) => (
                            <span
                                key={uuidv4()}
                                style={{
                                    fontWeight: part.highlight ? 600 : 400,
                                }}
                            >
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
        {helperText && <Component.FormHelperText error={error}>{helperText}</Component.FormHelperText>}
    </Component.ThemeProvider>
);

export default memo(InputSearch);
