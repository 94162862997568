import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

type Error = {
    name?: string;
    document?: string;
    email?: string;
    type?: string;
    main?: string;
    company?: string;
};

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    fields: any;
    error: Error;
    levelAccess?: number;
    userMain?: number;
    onChange: (event: any) => void;
    onChangeMultiple: (event: any) => void;
    onConfirm: () => void;
    onGoback: () => void;
    onClose: () => void;
}

const UserDetails: React.FC<Props> = ({
    open,
    status,
    message,
    fields,
    error,
    levelAccess,
    userMain,
    onChange,
    onChangeMultiple,
    onConfirm,
    onGoback,
    onClose,
}) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>
                            {fields.id !== ''
                                ? translate('modal.userDetails.title.update')
                                : translate('modal.userDetails.title.add')}
                        </Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form>
                                <Component.Grid container spacing={3}>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.userDetails.form.name')}
                                            name="name"
                                            value={fields.name}
                                            onChange={onChange}
                                            error={Boolean(error.name)}
                                            helperText={error.name}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.userDetails.form.document')}
                                            name="document"
                                            value={fields.document}
                                            onChange={onChange}
                                            error={Boolean(error.document)}
                                            helperText={error.document}
                                            mask="cpf"
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.userDetails.form.email')}
                                            name="email"
                                            value={fields.email}
                                            onChange={onChange}
                                            error={Boolean(error.email)}
                                            helperText={error.email}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.userDetails.form.address')}
                                            name="address"
                                            value={fields.address}
                                            onChange={onChange}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={4}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.userDetails.form.number')}
                                            name="number"
                                            value={fields.number}
                                            onChange={onChange}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={8}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.userDetails.form.city')}
                                            name="city"
                                            value={fields.city}
                                            onChange={onChange}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={8}>
                                        <Component.InputSelect
                                            color="primary"
                                            label={translate('modal.userDetails.form.state')}
                                            name="state"
                                            options={fields.states}
                                            value={fields.state}
                                            onChange={onChange}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={4}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.userDetails.form.postal_code')}
                                            name="postal_code"
                                            value={fields.postal_code}
                                            onChange={onChange}
                                            mask="cep"
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputSelect
                                            color="primary"
                                            name="type"
                                            label={translate('modal.userDetails.form.accessLevel')}
                                            options={fields.optionsTypes}
                                            value={fields.type}
                                            onChange={onChange}
                                            error={Boolean(error.type)}
                                            helperText={error.type}
                                        />
                                    </Component.Grid>
                                    {levelAccess === 1 && String(fields.type) === '2' && (
                                        <Component.Grid item xs={12}>
                                            <Component.InputSelect
                                                color="primary"
                                                name="main"
                                                label={translate('modal.userDetails.form.main')}
                                                options={fields.optionsMain}
                                                value={fields.main}
                                                onChange={onChange}
                                                error={Boolean(error.main)}
                                                helperText={error.main}
                                                disabled={levelAccess !== 1}
                                            />
                                        </Component.Grid>
                                    )}
                                    <Component.Visible
                                        condition={
                                            (levelAccess === 1 && String(fields.main) !== '1') ||
                                            (levelAccess === 2 && userMain === 1)
                                        }
                                    >
                                        <Component.Grid item xs={12}>
                                            <Component.InputSelect
                                                color="primary"
                                                name="company"
                                                label={translate('modal.userDetails.form.company')}
                                                options={fields.optionsCompanies}
                                                value={fields.company}
                                                onChange={onChange}
                                                error={Boolean(error.company)}
                                                helperText={error.company}
                                            />
                                        </Component.Grid>
                                    </Component.Visible>
                                    <Component.Grid item xs={12}>
                                        <Component.InputSelectMultiple
                                            color="primary"
                                            name="modules"
                                            label={translate('modal.userDetails.form.modules')}
                                            options={fields.optionsModules}
                                            value={fields.modules}
                                            onChange={onChangeMultiple}
                                            // disabled={levelAccess === 1}
                                        />
                                    </Component.Grid>
                                    {fields.modules.includes(8) && fields.optionsCourses.length > 0 && (
                                        <Component.Grid item xs={12}>
                                            <Component.InputSelectMultiple
                                                color="primary"
                                                name="courses"
                                                label={translate('modal.userDetails.form.courses')}
                                                options={fields.optionsCourses}
                                                value={fields.courses}
                                                onChange={onChangeMultiple}
                                                disabled={levelAccess === 1}
                                            />
                                        </Component.Grid>
                                    )}
                                    <Component.Grid item xs={12}>
                                        <Component.InputSelectMultiple
                                            color="primary"
                                            name="departaments"
                                            label={translate('modal.userDetails.form.departaments')}
                                            options={fields.optionsDepartaments}
                                            value={fields.departaments}
                                            onChange={onChangeMultiple}
                                        />
                                    </Component.Grid>
                                </Component.Grid>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button onClick={onConfirm}>
                                        {fields.id !== ''
                                            ? translate('modal.button.update')
                                            : translate('modal.button.add')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(UserDetails);
