import global from './translations/global.json';
import login from './translations/login.json';
import resetPassword from './translations/resetPassword.json';
import registrationSupplier from './translations/registrationSupplier.json';
import lgpdQuiz from './translations/lgpdQuiz.json';
import page404 from './translations/page-404.json';
import home from './translations/home.json';
import navbar from './translations/navbar.json';
import modal from './translations/modal.json';
import format from './translations/format.json';
import classification from './translations/classification.json';
import table from './translations/table.json';
import status from './translations/status.json';
import complaints from './translations/complaints.json';
import complaintsDetails from './translations/complaintsDetails.json';
import settings from './translations/settings.json';
import profile from './translations/profile.json';
import companies from './translations/companies.json';
import clients from './translations/clients.json';
import units from './translations/units.json';
import users from './translations/users.json';
import validation from './translations/validation.json';
import notPermission from './translations/notPermission.json';
import suppliers from './translations/suppliers.json';
import questionnaires from './translations/questionnaires.json';
import quiz from './translations/quiz.json';
import categories from './translations/categories.json';
import ead from './translations/ead.json';
import course from './translations/course.json';
import classContent from './translations/classContent.json';
import myCourses from './translations/myCourses.json';
import dashboardEad from './translations/dashboardEad.json';
import externalEad from './translations/externalEad.json';
import jobCandidates from './translations/jobCandidates.json';
import syndication from './translations/syndication.json';
import questions from './translations/questions.json';
import questionsLgpd from './translations/questionsLgpd.json';
import groupLgpd from './translations/groupLgpd.json';
import departaments from './translations/departaments.json';
import notifications from './translations/notifications.json';

const translate = {
    global,
    login,
    resetPassword,
    registrationSupplier,
    lgpdQuiz,
    page404,
    home,
    classification,
    table,
    status,
    navbar,
    modal,
    format,
    complaints,
    complaintsDetails,
    settings,
    validation,
    companies,
    clients,
    units,
    users,
    profile,
    notPermission,
    suppliers,
    questionnaires,
    quiz,
    categories,
    ead,
    course,
    classContent,
    myCourses,
    dashboardEad,
    externalEad,
    jobCandidates,
    syndication,
    questions,
    questionsLgpd,
    groupLgpd,
    departaments,
    notifications,
};

export default translate;
