import styled from 'styled-components';

import { Grid as CustomGrid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import Form, { InputText, InputSelect, InputSelectMultiple, Button } from 'components/form';
import CustomVisible from 'components/visible';

export { Form, InputText, InputSelect, InputSelectMultiple, Button };

export const Visible = styled(CustomVisible)``;

export const Modal = styled(Dialog)`
    && {
        .MuiPaper-root {
            min-width: 650px;
            min-height: 150px;
            background-color: #fff;
            background-image: linear-gradient(180deg, #fff, #f1f1f1, #eee);
            border-radius: 10px;

            @media (max-width: 768px) {
                min-width: auto;
            }
        }
    }
`;

export const ModalTitle = styled(DialogTitle)`
    color: #000;

    .MuiTypography-root {
        font-weight: 600;
    }
`;

export const ModalText = styled(DialogContentText)`
    color: #000;
`;

export const ModalBody = styled(DialogContent)`
    && {
        min-height: 150px;

        form {
            width: 100%;
        }
        .MuiFormControl-root {
            label {
                color: #b3b3b3;

                &.Mui-focused {
                    color: #424242;
                }

                &.Mui-error {
                    color: ${(props: any) => props.theme.palette.error.main};
                }
            }

            div {
                input {
                    color: #424242;
                }
                fieldset {
                    border-color: #424242;
                }

                &:hover {
                    input {
                        color: #424242;
                    }
                    fieldset {
                        border-color: #424242;
                    }
                }
            }

            &:hover {
                label {
                    color: #b3b3b3;

                    &.Mui-focused {
                        color: #424242;
                    }

                    &.Mui-error {
                        color: ${(props: any) => props.theme.palette.error.main};
                    }
                }
            }

            div {
                &.Mui-error {
                    fieldset {
                        border-width: 1px;
                        border-color: ${(props: any) => props.theme.palette.error.main};
                    }
                    input {
                        color: ${(props: any) => props.theme.palette.error.main};
                    }
                }
            }
        }
    }
`;

export const Grid = styled(CustomGrid)``;

export const ModalStatus = styled.div`
    height: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    margin: auto;
`;

export const ModalGroupMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    & > div {
        button {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
`;

export const ModalTextSuccess = styled.h3`
    font-size: 16px;
    font-weight: 400;
    text-align: center;
`;

export const Loading = styled(CircularProgress).attrs({
    size: 45,
})`
    color: #2b6838;
`;

export const IconSuccess = styled(CheckCircleOutlineIcon)`
    font-size: 60px;
    color: #2b6838;
    margin-bottom: 10px;
`;

export const IconError = styled(ErrorOutlineIcon)`
    font-size: 60px;
    color: #bd3336;
    margin-bottom: 10px;
`;

export const ModalActions = styled(DialogActions)`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    @media (max-width: 768px) {
        padding: 8px 0;
    }

    button {
        padding: 12px 25px;
        margin: 0 10px 15px;

        @media (max-width: 768px) {
            margin: 0 0 15px;
        }

        &:last-child {
            margin-right: 0;
        }

        &.MuiButton-containedPrimary {
            background-color: ${({ theme }: any) => theme.palette.button.tertiary.light[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.tertiary.light})`};

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.tertiary.main};
            }

            &:not(:disabled):hover {
                background-color: ${({ theme }: any) => theme.palette.button.tertiary.dark[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.tertiary.dark})`};
            }
        }
    }
`;
